import * as React from "react";

import { useQuery } from "@apollo/client";
import { ElementFromJSON, Element } from "@expediagroup/mojo-protocol-typescript-client";
import { Root } from "@expediagroup/mojo-protocol-react-adapters";
import { useBexApi } from "@shared-ui/bex-api-context";

import { MojoQueryDocument } from "__generated__/typedefs";

import { MojoQueryComponentProps } from "../typings";

export const MojoHome: React.FC<MojoQueryComponentProps> = (props) => {
  const { context, shouldSkipQuery, templateComponent } = props;

  const mojoContext = {
    pageName: context.searchContext.pageType.toString(),
    placementId: templateComponent.config.placementId,
  };

  const variables = {
    context: useBexApi().context,
    mojoContext,
  };

  const { data, error, loading } = useQuery(MojoQueryDocument, {
    variables,
    skip: shouldSkipQuery,
  });

  if (error || loading || !data || !data.mojoSection?.data) {
    return null;
  }

  const json = JSON.parse(data.mojoSection.data);
  const element: Element = ElementFromJSON(json);

  return (
    <div data-testid="mojo">
      <Root elements={[element]} />
    </div>
  );
};
