import * as React from "react";
import { useLocation } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { ElementFromJSON, Element } from "@expediagroup/mojo-protocol-typescript-client";
import { Root } from "@expediagroup/mojo-protocol-react-adapters";
import { useBexApi } from "@shared-ui/bex-api-context";

import { MojoLandingQueryDocument } from "__generated__/typedefs";

import { MojoQueryComponentProps } from "../typings";

export const MojoLanding: React.FC<MojoQueryComponentProps> = (props) => {
  const { context, shouldSkipQuery, templateComponent } = props;
  const location = useLocation();
  const path = location.pathname || "";
  const search = location.search || "";
  const url = `${path}${search}`;

  const variables = {
    context: useBexApi().context,
    mojoContext: {
      pageName: context.searchContext.pageType.toString(),
      placementId: templateComponent.config.placementId,
    },
    url,
  };

  const { data, error, loading } = useQuery(MojoLandingQueryDocument, {
    variables,
    skip: shouldSkipQuery,
  });

  if (error || loading || !data?.mojoLandingSection?.data) {
    return null;
  }

  const json = JSON.parse(data.mojoLandingSection.data);
  const element: Element = ElementFromJSON(json);

  return (
    <div data-testid="mojo-landing">
      <Root elements={[element]} />
    </div>
  );
};

MojoLanding.displayName = "MojoLanding";
