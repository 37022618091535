import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "src/stores";
import { MojoProps } from "./typings";
import { ElementFromJSON } from "@expediagroup/mojo-protocol-typescript-client";
import { Root } from "@expediagroup/mojo-protocol-react-adapters";
import { useMojoData } from "src/components/utility/Mojo/MojoDataContext";

import { MojoHome } from "./components/MojoHome";
import { MojoLanding } from "./components/MojoLanding";

export const Mojo = withStores("context")(
  observer((props: MojoProps) => {
    const { templateComponent, context } = props;

    const { element: mockedElement, shouldSkipQuery } = useMojoData();

    if (shouldSkipQuery) {
      return (
        <div data-testid="mojo-mocked">{mockedElement && <Root elements={[ElementFromJSON(mockedElement)]} />}</div>
      );
    }

    const mojoProps = {
      context,
      templateComponent,
      shouldSkipQuery,
    };

    switch (templateComponent.config.dataSource) {
      case "landing":
        return <MojoLanding {...mojoProps} />;
      default:
        return <MojoHome {...mojoProps} />;
    }
  })
);

export default Mojo;
